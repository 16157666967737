/* eslint-disable prefer-destructuring, react/jsx-no-constructed-context-values, react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_CONFIGS } from 'src/constants';
import useSearchQueryParams from 'src/hooks/useSearchQueryParams';
// import { getI18n, getLanguages } from 'src/services/apis/system';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
// import { setHeaderLocale } from 'src/helpers/request';
// import isEmpty from 'lodash/isEmpty';
import { genUUID } from 'src/helpers';
// import i18nProvider from 'src/constants/language/i18nProvider';

export const LanguageContext = createContext({
  translations: {},
  in18n: {},
});

export const useLanguageContext = () => useContext(LanguageContext);

export function LanguageContextProvider({ children }) {
  const groupCode = useSearchQueryParams(
    'groupCode',
    DEFAULT_CONFIGS.groupCode,
  );

  const brandCode = useSearchQueryParams(
    'brandCode',
    DEFAULT_CONFIGS.brandCode,
  );

  const playerToken = useSearchQueryParams(
    'playerToken',
    DEFAULT_CONFIGS.playerToken,
  );

  // const paramLanguage = useSearchQueryParams('language');

  const [localStorageLocale, setLocalStorageLocale] = useLocalStorage(
    `${groupCode}-${brandCode}-${playerToken}-locale`,
  );

  const [translations] = useState({});

  const [i18n, setI18n] = useState(() => {
    if (localStorageLocale) {
      // setHeaderLocale(localStorageLocale);
      return localStorageLocale;
    }

    return {};
  });

  const [languages] = useState([]);
  const [defaultLanguage, setDefaultLanguages] = useState({});

  const getDataLanguages = async () => {
    // try {
    //   const { defaultLanguage: resDefaultLanguage, languages: resLanguages } =
    //     await getLanguages({
    //       groupCode,
    //       brandCode,
    //     });
    //   setLanguages(resLanguages);

    //   const firstLocale = paramLanguage || localStorageLocale;

    //   const languageFromUrl = resLanguages?.find(
    //     (l) => l.locale === firstLocale,
    //   );

    //   if (languageFromUrl) {
    //     setDefaultLanguages({
    //       ...languageFromUrl,
    //     });
    //   } else {
    //     const defaultLang = !isEmpty(resDefaultLanguage)
    //       ? resDefaultLanguage
    //       : resLanguages[0];
    //     setDefaultLanguages(defaultLang);
    //   }
    // } catch (error) {
      setDefaultLanguages({
        locale: 'en',
        id: genUUID('en', 'Language'),
        code: 'en',
        name: 'English',
      });
      // console.debug('Error: at fetching languages', error);
    // }
  };

  const getDataI18n = async (locale) => {
    // try {
    //   const { translations: resTranslation } = await getI18n({
    //     locale,
    //   });

    //   const defaultLanguages = i18nProvider(locale);

    //   const data = resTranslation.reduce(
    //     (acc, { key, translation }) => ({
    //       ...acc,
    //       ...defaultLanguages,
    //       [key]: translation,
    //     }),
    //     {},
    //   );

    //   setTranslations(data);
    //   setLocalStorageLocale(i18n.locale);
    // } catch (error) {
    //   console.debug('Error: at fetching lobby url\n', error);
    // }
  };

  useEffect(() => {
    getDataLanguages();
  }, []);

  useEffect(() => {
    if (defaultLanguage.locale) {
      setI18n(defaultLanguage);
      // setHeaderLocale(defaultLanguage.locale);
    }
  }, [defaultLanguage]);

  useEffect(() => {
    if (i18n?.locale) {
      getDataI18n(i18n?.locale);
    }
  }, [i18n?.locale]);

  return (
    <LanguageContext.Provider
      value={{ languages, i18n, setI18n, translations, setLocalStorageLocale }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

LanguageContextProvider.propTypes = {
  children: PropTypes.node,
};
