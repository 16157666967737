/* eslint-disable prefer-destructuring, react/jsx-no-constructed-context-values, react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
// import { getLobbyPage } from 'src/services/apis/system';
// import { DEFAULT_CONFIGS } from 'src/constants';
// import useSearchQueryParams from 'src/hooks/useSearchQueryParams';

export const AppContext = createContext({
  lobbyPageUrl: '',
  player: {
    currency: '',
  },
});

export const useAppContext = () => useContext(AppContext);

export function AppContextProvider({ children }) {
  const [playerCurrency, setPlayerCurrency] = useState();
  const [lobbyPageUrl, setLobbyPageUrl] = useState();

  // const groupCode = useSearchQueryParams(
  //   'groupCode',
  //   DEFAULT_CONFIGS.groupCode,
  // );
  // const brandCode = useSearchQueryParams(
  //   'brandCode',
  //   DEFAULT_CONFIGS.brandCode,
  // );
  // const playerToken = useSearchQueryParams(
  //   'playerToken',
  //   DEFAULT_CONFIGS.playerToken,
  // );

  const handleGetLobbyUrl = async () => {
    // try {
    //   const data = await getLobbyPage({
    //     playerToken,
    //     groupCode,
    //     brandCode,
    //   });

    setLobbyPageUrl(window.location);
    // } catch (error) {
    //   console.debug('Error: at fetching lobby url\n', error);
    // }
  };

  useEffect(() => {
    handleGetLobbyUrl();
  }, []);

  return (
    <AppContext.Provider
      value={{
        lobbyPageUrl,
        player: {
          currency: playerCurrency,
        },
        setPlayerCurrency,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

AppContextProvider.propTypes = {
  children: PropTypes.node,
};
